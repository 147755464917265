<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.09863 9.99219V18.2391"
      stroke="#1F002A"
      stroke-miterlimit="10" />
    <path
      d="M9.69922 9.99219V18.2391"
      stroke="#1F002A"
      stroke-miterlimit="10" />
    <path
      d="M14.2998 9.99219V18.2391"
      stroke="#1F002A"
      stroke-miterlimit="10" />
    <path
      d="M18.8999 9.99219V18.2391"
      stroke="#1F002A"
      stroke-miterlimit="10" />
    <path
      d="M18.9 16.4312H5.09863V18.2313H18.9077V16.4312H18.9Z"
      fill="#1F002A" />
    <path
      d="M21.4157 7.37649V9.10743C21.4157 9.59209 21.0234 9.98444 20.5387 9.98444H3.46783C2.98317 9.98444 2.59082 9.59209 2.59082 9.10743V7.37649C2.59082 7.05339 2.76776 6.75336 3.0524 6.5995L11.4071 2.14522C11.7763 1.9529 12.2148 1.9529 12.5841 2.14522L20.9464 6.5995C21.2388 6.75336 21.408 7.05339 21.408 7.37649H21.4157Z"
      stroke="#1F002A"
      stroke-miterlimit="10" />
    <path
      d="M20.531 18.2393H3.46783C2.98317 18.2393 2.59082 18.6316 2.59082 19.1163V21.1242C2.59082 21.6088 2.98317 22.0012 3.46783 22.0012H20.5387C21.0234 22.0012 21.4157 21.6088 21.4157 21.1242V19.1163C21.4157 18.6316 21.0234 18.2393 20.5387 18.2393H20.531Z"
      stroke="#1F002A"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M11.9996 8.1998C12.8689 8.1998 13.5689 7.49973 13.5689 6.63042C13.5689 5.7611 12.8689 5.06104 11.9996 5.06104C11.1302 5.06104 10.4302 5.7611 10.4302 6.63042C10.4302 7.49973 11.1302 8.1998 11.9996 8.1998Z"
      fill="#1F002A" />
  </svg>
</template>