<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.99967 0.666748C2.36786 0.666748 2.66634 0.965225 2.66634 1.33341V4.66675H5.99967C6.36786 4.66675 6.66634 4.96523 6.66634 5.33342C6.66634 5.7016 6.36786 6.00008 5.99967 6.00008H1.99967C1.63148 6.00008 1.33301 5.7016 1.33301 5.33342V1.33341C1.33301 0.965225 1.63148 0.666748 1.99967 0.666748Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.59229 1.37892C9.08411 1.29507 10.5609 1.71481 11.7856 2.57079C13.0102 3.42677 13.9119 4.66935 14.3458 6.09914C14.7797 7.52893 14.7207 9.06304 14.1782 10.4553C13.6358 11.8475 12.6414 13.0172 11.3546 13.7766C10.0678 14.5359 8.56313 14.841 7.08216 14.6428C5.60118 14.4447 4.22971 13.7547 3.18783 12.6837C2.14594 11.6127 1.49405 10.2227 1.33677 8.73686C1.29801 8.37072 1.56341 8.04248 1.92955 8.00372C2.2957 7.96496 2.62393 8.23036 2.66269 8.5965C2.78852 9.78521 3.31003 10.8972 4.14354 11.754C4.97705 12.6108 6.07422 13.1627 7.259 13.3213C8.44379 13.4798 9.64748 13.2358 10.6769 12.6283C11.7064 12.0208 12.5019 11.085 12.9359 9.97124C13.3698 8.85745 13.417 7.63016 13.0699 6.48633C12.7228 5.34249 12.0015 4.34843 11.0217 3.66365C10.042 2.97887 8.86057 2.64307 7.66711 2.71015C6.47478 2.77716 5.33948 3.2424 4.44302 4.03129L2.44571 5.82888C2.17203 6.07518 1.75051 6.053 1.5042 5.77933C1.25789 5.50565 1.28008 5.08413 1.55375 4.83782L3.55896 3.03313C4.68003 2.04531 6.10046 1.46276 7.59229 1.37892Z"
      fill="currentColor" />
  </svg>
</template>