import useBackend from "@/services/api2.service";
import useEventService from "@/composables/useEventService";
import { AccountFilterParser } from "./aa-filters";
import useUtilService from "@/services/util.service";
import { PLATFORM } from "@finarkein/event-service";
import { storeToRefs } from "pinia";
import { Logger } from "tslog";
import { AxiosError } from "axios";
import { useResetStore } from "@/services/reset-store";
import {
  AuthInitRequest,
  LinkedAccount,
  ConsentAction,
} from "@finarkein/aasdk-core";
import {
  COMPONENT_VIEW_TYPE,
  ERROR_RESPONSE_CODES,
  JOURNEY_LAYOUTS,
  JOURNEY_STATUS_ENUM,
  REDIRECT_MODE_ENUM,
  SELECTION_MODE,
} from "@/constants/constants";
import emitter from "@/composables/event.emitter";
import {
  AccountLinkedResProto,
  AccountLinkingResponseProto,
  DiscoveryStatus,
  FinancialAccount,
  Institution,
  JourneyStoreType,
} from "./models";
import { computed, ref, watch } from "vue";
import { DefaultFeatures } from "./journey-features";
import {
  ConductorEvent,
  ConductorFeatures,
  ErrorCodes,
  ErrorMessages,
  ErrorTypes,
  InternalConductorEvent,
  JourneyType,
  LastStatus,
} from "./journey-constants";
import { DefaultTemplates } from "./journey-template";
import moment from "moment";
import router from "@/router";
import useFeatures from "@/services/feature.service";
import { useAAXJourneyStore } from "../store/aaX-journey.store";
import { API_ERROR_RESPONSE_CODES } from "./journey-constants";
import { v2Views } from "./views";
import {
  AuthInitErrorHandler,
  AuthVerifyErrorHandler,
  AccountDiscoveryErrorHandler,
  MobileAuthInitErrorHandler,
  FetchLinkedAccountsErrorHandler,
  LinkAccountsErrorHandler,
  ConfirmAccountLinkingErrorHandler,
  ConsentRequestErrorHandler,
  ConsentActionErrorHandler,
  UserInfoErrorHandler,
  MobileAuthVerifyErrorHandler,
  BaseErrorHandlerFactory,
} from "@/conductor/ErrorHandlers";

const log = new Logger({
  name: "[conductor-lite]",
  // prefix: ['[conductor-lite]'],
  prettyLogTimeZone: "local",
  hideLogPositionForProduction: true, // process.env.NODE_ENV !== 'production'
});

export const eventPayload = (
  requestId: string,
  discriminator: number,
  fields?: Record<string, any>,
  aaErrorCtx = {},
) => {
  return {
    requestId,
    discriminator,
    timestamp: new Date().toISOString(),
    ...fields,
    ...aaErrorCtx,
  };
};

// store is storeToRefs store, which is two way
export default function useConductor(
  aaStore: JourneyStoreType,
  aaViews: any,
  layoutView: string,
) {
  const store = aaStore;
  const views = aaViews;
  const events = useEventService();
  const api = useBackend();
  const utils = useUtilService();
  const eventEmitter = emitter;
  const {
    filterParser,
    lastKnownStatus,
    viewHandler,
    aaSdkAdapter,
    aaHandle,
    extraIdentifiersList,
    aaSdk,
    isProcessing,
    otpReference,
    awaitNext,
    exitWorld,
    aaAuth,
    institutionWiseAccounts,
    autoDiscoveryCount,
    anythingLoading,
    bankFound,
    showOtpSentPopup,
    totalAccountsCount,
    consentAction,
    removeListener,
    missingAcc,
    currentView,
    features,
    autoRetryCount,
    previousHandler,
    fipFilters,
    encryptedParams,
    discriminator,
    errorObject,
    webRedirectUrl,
    tenantId,
    extraUserDetails,
    askAdditionalInfo,
    somethingLoading,
    extraJourneyReq,
    userConsentedAccounts,
    maxOTPLimit,
  } = storeToRefs(store);

  const resetStore = useResetStore();
  const xStore = useAAXJourneyStore();

  // v2X data
  const {
    errorXValue,
    xid,
    reqId,
    forTenantRedirectParams,
    showRedirectButton,
    redirectMode,
    customAAJourneyList,
    brandInfo,
    tId,
    sessionError,
  } = storeToRefs(xStore);
  const journeyStatus = JOURNEY_STATUS_ENUM;

  /**
   * Initialize conductor using just the request identifier.
   *
   * @param requestId
   */
  // const consentRes = {} as any;

  const errorHandlerContext = {
    denyAndExit,
    retryLogin,
    layoutView,
    sessionError,
    store,
    events,
    fireJourneyEvents,
  };

  const baseErrorHandlerFactory = new BaseErrorHandlerFactory(errorHandlerContext);
  const handleBadRequest = baseErrorHandlerFactory.handleBadRequest;
  const handleFailures = baseErrorHandlerFactory.handleFailures;
  // if the session is expired, ask user to login and restart again
  const handleSessionError = baseErrorHandlerFactory.handleSessionError;
  const invokeTroubleshoot = baseErrorHandlerFactory.invokeTroubleshoot;
  const aaErrorContext = baseErrorHandlerFactory.aaErrorContext;

  // get landing feature - currently for SBI LITE
  const showlanding = computed(() => {
    return store.getFeature(ConductorFeatures.LANDING_PAGE, false);
  });

  /**
   * To be called during initialization, once request details are resolved.
   * @param safePackages valid packages (android) to be allowed by SDK
   * @param color to use for setting the SDK accent
   */
  function configureSdk(color: string, safePackages: string[] = []) {
    // Ask SDK to allow current journey
    events.fire(
      ConductorEvent.SAFE_PACKAGES,
      eventPayload(store.requestId, store.discriminator, {
        // TODO: Configre SDK, remove following hard-coded safe pacakges list
        SAFE_PACKAGES: [
          "com.naviapp",
          "com.naviapp.dev",
          "com.naviapp.fps.dev",
          "com.naviapp.fps",
          "com.infrasofttech.CentralBank",
          "com.infrasofttech.CentralBank.sit",
          "com.infrasofttech.CentralBank.bankuat",
          "com.infrasofttech.CentralBank.cug",
          "com.smallcase.android",
          "in.finarkein.anubhav.app",
          "in.finarkein.anubhav.app.dev",
          "in.finarkein.anubhav.app.prod",
          "io.finarkein.portfos.app",
          ...safePackages,
        ],
      }),
      undefined,
      { deliverToHub: false, listeners: [PLATFORM.android] },
    );
    const manageBack = store.getFeature(ConductorFeatures.MANAGE_BACK_BUTTON, false);
    // Update the accent the of the SDK
    events.fire(
      ConductorEvent.ANUBHAV_CONFIG,
      eventPayload(store.requestId, store.discriminator, { color, managedPhysicalBack: manageBack }),
      undefined,
      { deliverToHub: false, listeners: [PLATFORM.android] },
    );
  }

  const init = async (requestId: string, view?: any) => {
    lastKnownStatus.value = LastStatus.REQUIRES_INIT;
    isProcessing.value = true;
    events.fire(
      ConductorEvent.OPEN,
      eventPayload(requestId, discriminator.value),
    );

    // for V2X as it has query params
    store.preInit(requestId);
    if (
      extraJourneyReq.value !== undefined
      && extraJourneyReq.value.jtype === JourneyType.ANUBHAV_X
    ) {
      await getXDetails();
      configureSdk(xStore.brandInfo.color); // TODO: Configure SDK, handle missing safe packages
      isProcessing.value = false;
    } else if (
      extraJourneyReq.value !== undefined
      && extraJourneyReq.value.jtype === JourneyType.SINGLE_CONSENT_WITH_PARAMS
    ) {
      return await api.getRequestDetails(requestId).then(async (f) => {
        await resolveConfigCatFeatures();
        store.updateFromRequestDetails(requestId, f);
        configureSdk(store.brand.color); // TODO: Configure SDK, handle missing safe packages
        transitionToView(views.thankYou);
        isProcessing.value = false;
      });
    } else if (extraJourneyReq?.value.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT || extraJourneyReq?.value.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT_IN_IFRAME) {
      console.info("[mult-consent] Mode active (auth)");
      // [1] collect all child request identifiers, store the details (next used during consent grant flow)
      // [2] Use one of the request identifier to resolve required information
      const parentId = requestId;
      store.parentId = requestId;
      return await api
        .getChildRequestIds(parentId, store.extraJourneyReq?.receivedAuth)
        .then((childReqIds: string[]) => {
          console.info(
            `[mult-consent]-[${parentId}]: resolved sub-request identifiers ${childReqIds.toString()}`,
          );
          store.preInit(requestId, childReqIds); // update the internal processIds
          const reqDetailsPromises = childReqIds.map((childId) => {
            return api
              .getRequestDetails(childId, store.extraJourneyReq?.receivedAuth)
              .then((reqDetails) => {
                return {
                  activeRequestId: childId,
                  data: reqDetails,
                };
              });
          });

          return Promise.all(reqDetailsPromises);
        })
        .then(async (responses) => {
          // !!! CODE DUPLICATION: start
          responses.forEach((res) => {
            console.info(
              `[mult-consent]-[${parentId}]: updating request details for {${res.activeRequestId}}`,
            );
            store.updateFromRequestDetails(
              parentId,
              res.data,
              res.activeRequestId,
            );
          });

          // use first response object data for filling/fetching required details
          const data = responses[0].data;
          const activeRequestId = responses[0].activeRequestId;
          await setJourneyFeatures(data);
          await setJourneyTemplates(data);
          await resolveConfigCatFeatures();
          configureSdk(store.brand.color); // TODO: Configure SDK, handle missing safe packages
          if (store.extraJourneyReq?.receivedAuth) {
            await fetchUserConsentedAccounts();
          }
          // fetchUserConsentedAccounts();
          lastKnownStatus.value = LastStatus.REQUIRES_FIP_SELECTION;
          // Decide one of two things
          // 1. Show bank-selection (if not filters are available)
          // 2. Directly move to AA-selection
          // encryptedParams.value = await updateAndGetWebviewParams(aaHandle.value);
          if (
            data
            && (data.flowState === "SUCCESS" || data.flowState === "RUNNING")
          ) {
            const error = {
              errorCode: API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED,
              errorMessage:
                data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                  ? API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                  : API_ERROR_RESPONSE_CODES.LINK_EXPIRED,
            };
            errorObject.value = error;
            if (layoutView != JOURNEY_LAYOUTS.V2) {
              handleAPIError(
                "Err, already processed!",
                "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
                "Invalid Request ID/URL provided",
              );
            }
            eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
          }
          await getListOfFips(activeRequestId);
          if (data?.accountFilters && utils.removeEmptyObjects(data?.accountFilters).length > 0) {
            // parentId here used for generating events, nothing else
            handleAvailableAccountFilters(data, parentId, view);
          } else {
            // parentId here used for generating events, nothing else
            handleUnavailableAccountFilters(data, parentId, view);
          }
        })
        .catch((e: any) => {
          console.log(e);
          log.fatal("Failed while initializing the journey", e?.message);
          if (e instanceof AxiosError) {
            const result = apiErrorHandler(e, events, requestId);
            if (result === true) {
              return; // return only if apiErrorHandler is showing modal to the user
            }
          }

          _fireExit(
            "UNKNOWN",
            "API_ERROR",
            "Unhandled API error, reach out to Finarkein support",
          );
        }).finally(() => {
          isProcessing.value = false;
        });
      // !!! CODE DUPLICATION: end
    } else {
      return await api
        .getRequestDetails(requestId, store.extraJourneyReq?.receivedAuth)
        .then(async (data) => {
          // IMPORTANT!
          store.updateFromRequestDetails(requestId, data);

          await setJourneyFeatures(data);
          await setJourneyTemplates(data);
          await resolveConfigCatFeatures();
          configureSdk(store.brand.color); // TODO: Configure SDK, handle missing safe packages
          // await fetchUserConsentedAccounts();
          if (store.extraJourneyReq?.receivedAuth) {
            await fetchUserConsentedAccounts();
          }
          lastKnownStatus.value = LastStatus.REQUIRES_FIP_SELECTION;
          // Decide one of two things
          // 1. Show bank-selection (if not filters are available)
          // 2. Directly move to AA-selection
          // encryptedParams.value = await updateAndGetWebviewParams(aaHandle.value);

          if (data && data.flowState === "FAILED") {
            eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
            return;
          } else if (data && (data.flowState === "SUCCESS" || data.flowState === "RUNNING")) {
            eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
            if (layoutView != JOURNEY_LAYOUTS.V2) {
              handleAPIError("Err, already processed!",
                "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
                "Invalid Request ID/URL provided", ErrorCodes.ALREADY_COMPLETED);
            }

            return;
          }
          await getListOfFips(store.requestId);
          if (data?.accountFilters && utils.removeEmptyObjects(data?.accountFilters).length) {
            handleAvailableAccountFilters(data, requestId, view);
          } else {
            handleUnavailableAccountFilters(data, requestId, view);
          }
          isProcessing.value = false;

          // TODO: handle show bank selection screen
          // _fireExit("INVALID_INSTITUTION", "INPUT_ERROR", ErrorMessages.NO_INSTITUTION)
        })
        .catch((e: any) => {
          console.log(e);
          log.fatal("Failed while initializing the journey", e?.message);
          if (e instanceof AxiosError) {
            const result = apiErrorHandler(e, events, requestId);
            if (result === true) {
              return; // return only if apiErrorHandler is showing modal to the user
            }
          }

          _fireExit(
            "UNKNOWN",
            "API_ERROR",
            "Unhandled API error, reach out to Finarkein support",
          );
          isProcessing.value = false;
        });
    }
  };

  function transitionToView(view: any, layout = JOURNEY_LAYOUTS.V2) {
    if (view) {
      if (view.comp) {
        // Don't tranisiton for duplicate calls! and log!
        if (currentView.value === view.name) {
          log.error(
            `[NEED REFACTOR]: Duplicate transition requested [${view.name}]`,
          );
          return;
        }

        previousHandler.value = currentView.value;
        viewHandler.value = view.comp;
        currentView.value = view.name;

        // separate the view related features heree
        /*  const viewTemplates = filterTemplates("view", store.features);
        store.viewsType = utils.splitTemplateString(viewTemplates, 3, 4); */
        events.fire(
          ConductorEvent.TRANSITION_VIEW,
          eventPayload(store.requestId, discriminator.value, {
            name: view.name,
            layout: layoutView ? layoutView : layout,
          }),
        );
      }
    }
  }

  // ==================
  // Consent Detail
  // =================

  // function consentDetail(requestId: string) {
  //   useBackend().getRequestDetails(requestId)
  //   .then((response: any) => {
  //     console.log(response.webviewConsentTemplate.def);
  //   })
  // }

  // ==================
  // AA LOGIN stuff : start
  // =================

  async function prepareAccountAggregator() {
    // try and use the filters and/or stored selected FIP(s)
    lastKnownStatus.value = LastStatus.REQUIRES_AA_OTP;
    // trigger the next screen process
    const isParent
      = extraJourneyReq?.value.jtype
      === JourneyType.AUTHORIZED_MULTI_CONSENT_IN_IFRAME
        ? true
        : false;
    await updateAndGetWebviewParams(aaHandle.value, isParent);
    if (
      customAAJourneyList.value
      && !customAAJourneyList.value.includes(aaHandle.value)
      && layoutView === JOURNEY_LAYOUTS.V2
      && webRedirectUrl.value
      && !store.isIframeAllowed
    ) {
      window.location.href = webRedirectUrl.value;
      // window.open(webRedirectUrl.value);
    } else if (
      store.isIframeAllowed
      && layoutView === JOURNEY_LAYOUTS.V2
      && !customAAJourneyList.value.includes(aaHandle.value)
    ) {
      transitionToView(views.iframeWrapper);
    } else {
      awaitNext.value = _initForAaLogin();
    }
  }

  const apiErrorHandler = (error: AxiosError, events: any, reqId: string) => {
    // transitionToView(views.errorPage);
    if (error.code == "ERR_NETWORK") {
      eventEmitter.emit(InternalConductorEvent.NETWORK_ERROR);
      events.fire(
        ConductorEvent.ERROR,
        eventPayload(reqId, discriminator.value, {
          errorCode: ErrorCodes.NO_NETWORK,
          errorMessage: error.message,
          errorType: ErrorTypes.NET_ERROR,
        }),
      );

      const errValue = {
        errorCode: ERROR_RESPONSE_CODES.LINK_EXPIRED,
        errorMessage:
          "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
      };
      errorObject.value = errValue;
      transitionToView(views.errorPage);
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);

      // Known API status codes handling
      const data: any = error.response.data;
      if (error.response.status === 409) {
        events.fire(ConductorEvent.API_ERROR, eventPayload(reqId, discriminator.value, {
          errorCode: data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED ? ErrorCodes.ALREADY_COMPLETED : ErrorCodes.LINK_EXPIRED,
          errorMessage: "Journey is already completed",
        }));
        if (
          data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
          || data?.errorCode === ERROR_RESPONSE_CODES.LINK_EXPIRED
        ) {
          eventEmitter.emit(InternalConductorEvent.JOURNEY_COMPLETED);
          const error = {
            errorCode: data?.errorCode,
            errorMessage:
              data?.errorCode === ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                ? API_ERROR_RESPONSE_CODES.ALREADY_COMPLETED
                : API_ERROR_RESPONSE_CODES.LINK_EXPIRED,
          };
          errorObject.value = error;
          if (layoutView != JOURNEY_LAYOUTS.V2) {
            handleAPIError(
              "Err, already processed!",
              "You're trying to visit a journey that is already completed or expired. If you feel this is in error, please reach out to Support.",
              "Invalid Request ID/URL provided",
            );
          }

          return true;
        }
      }
      // if the request id is invalid
      if (error.response.status === 404) {
        if (data?.errorCode === ERROR_RESPONSE_CODES.DATA_NOT_FOUND) {
          if (layoutView != JOURNEY_LAYOUTS.V5) {
            handleAPIError(
              "Sorry Could not fetch details",
              "Please try other methods to upload bank statement",
              "Invalid Request ID/URL provided",
            );

            eventEmitter.emit(InternalConductorEvent.INVALID_URL);
            return true;
          } else {
            eventEmitter.emit(InternalConductorEvent.INVALID_URL);
          }
        }
      }
      // any internal error
      if (error.response.status === 500) {
        if (
          data?.errorCode === ERROR_RESPONSE_CODES.IN_PROCESS
          || data?.errorCode === ERROR_RESPONSE_CODES.INTERNAL_ERROR
        ) {
          const error = {
            errorCode: data?.errorCode,
            errorMessage:
              data?.errorCode === ERROR_RESPONSE_CODES.IN_PROCESS
                ? API_ERROR_RESPONSE_CODES.IN_PROCESS
                : API_ERROR_RESPONSE_CODES.INTERNAL_ERROR,
          };
          errorObject.value = error;
          handleAPIError(
            "Sorry Could not fetch details",
            "Please try other methods to upload bank statement",
            "Service Unavailable.",
            ErrorCodes.ALTERNATIVE_CHOSEN,
            ErrorTypes.FLOW_ERROR,
          );
          return true;
        }
      }

      if (error.response.status === 400) {
        if (data?.errorCode === ERROR_RESPONSE_CODES.INVALID_REQUEST) {
          const error = {
            errorCode: data?.errorCode,
            errorMessage: API_ERROR_RESPONSE_CODES.INVALID_REQUEST,
          };
          errorObject.value = error;
          handleAPIError(
            "Sorry Could not fetch details",
            "Please try other methods to upload bank statement",
            "Service Unavailable.",
            ErrorCodes.ALTERNATIVE_CHOSEN,
            ErrorTypes.FLOW_ERROR,
          );
        } else {
          handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.API_ERROR, error.message, false);
        }
        return true;
      }

      events.fire(
        ConductorEvent.ERROR,
        eventPayload(reqId, discriminator.value, {
          errorCode: error.response.status.toString(),
          errorMessage: error.message,
          errorType: ErrorTypes.API_ERROR,
        }),
      );
    } else if (error.request) {

      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {

      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
  };

  async function resendAaOTP() {
    // reset store to get updated valuee
    store.otpReference = undefined;
    return await _sendOtpWithAA(true);
  }

  async function _sendOtpWithAA(isRetry = false) {
    // At this stage, SDK Adapter must have been initialized, else this is way way wrong!
    aaSdk.value = aaSdkAdapter.value.getSdk(aaHandle.value);
    aaSdk.value.cleanUp();

    // If journey type MULTI CONSENT, then we need to initiate login with only mobile
    // const authInitInput: Partial<AuthInitRequest> =
    //   store.extraJourneyReq.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT
    //     ? {
    //         mobileNum: store.customer.mobile,
    //       }
    //     : (encryptedParams.value as AuthInitRequest);

    // If webview call fails dont start the authinit operation
    if (!encryptedParams.value) { // TODO: change this check to webview reqeust status instead
      log.silly("No data from webview");
      return Promise.reject(new Error("Encrypted Params not initialized"));
    }

    const authInitInput: Partial<AuthInitRequest>
      = encryptedParams.value as AuthInitRequest;
    if (store.customer.mobile && aaHandle.value !== "finvu") {
      authInitInput.mobileNum = store.customer.mobile;
    }
    flaggedEvents(isRetry ? ConductorEvent.PRE_AA_OTP_RESENT : ConductorEvent.PRE_AA_OTP_SENT, eventPayload(store.requestId, discriminator.value, {
      aaHandle: aaHandle.value,
    }), { internal: true });

    return aaSdk.value
      ?.authInit(authInitInput)
      .then(async (response: any) => {
        otpReference.value = response.data.otpReference;
        log.silly(
            `${isRetry ? "Re-s" : "S"}ent OTP from Account Aggregator`,
            aaHandle.value,
        );
        events.fire(
          isRetry ? ConductorEvent.AA_OTP_RESENT : ConductorEvent.AA_OTP_SENT,
          eventPayload(store.requestId, discriminator.value, {
            aaHandle: aaHandle.value,
          }),
        );
        showOtpSentPopup.value = true;

        // Hide the popup after 5 seconds
        setTimeout(() => {
          showOtpSentPopup.value = false;
        }, 5000);
      })
      .catch((e: any) => {
        console.log("errror from auth verify", e);
        const handler = baseErrorHandlerFactory.createErrorHandler(AuthInitErrorHandler);
        handler.processError(e, isRetry);
        maxOTPLimit.value = e?.message;
      }).finally(() => {
        flaggedEvents(isRetry ? ConductorEvent.POST_AA_OTP_RESENT : ConductorEvent.POST_AA_OTP_SENT, eventPayload(store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
        }), { internal: true });
      });
  }

  async function _initForAaLogin() {
    // Initialize based on selected account aggregator
    await aaSdkAdapter.value.setUpSdk(aaHandle.value);
    log.silly("Initiating OTP from Account Aggregator", aaHandle.value);
    return await _sendOtpWithAA();
  }

  async function updateAndGetWebviewParams(aaHandle: string, isParent = false) {
    let requestIds = store.processIds ?? [xStore.reqId];
    isProcessing.value = true;
    let routeTo;
    if (layoutView === JOURNEY_LAYOUTS.V2) {
      routeTo = "v2u.landing";
    }

    const finalRouteTo = routeTo;
    if (isParent) {
      requestIds = [store.parentId];
    }
    const promises = requestIds.map(id =>
      api[isParent ? "getParentWebviewDetails" : "getWebviewDetails"](
        id,
        { aaHandle },
        tenantId.value,
        finalRouteTo,
        extraJourneyReq.value?.receivedAuth,
      )
        .then(res => ({
          id: id,
          status: "fulfilled",
          data: res.data,
        }))
        .finally(() => {
          isProcessing.value = false;
        }),
    );

    const responses = (await Promise.allSettled(promises)) as any;

    const successfulResponses = responses.filter(
      (response: { status: string }) => response.status === "fulfilled",
    );
    const failedResponses = responses.filter(
      (response: { status: string }) => response.status === "rejected",
    );
    log.silly("Successfully resolved request details");
    if (successfulResponses.length > 0) {
      webRedirectUrl.value = successfulResponses[0].value.data.redirectUrl; // backward compatibility
    }
    let lastEncryptedParams;
    successfulResponses.forEach((response: any) => {
      // overwrite: backward compatibility
      lastEncryptedParams = utils.getEncrypedParams(
        response.value.data.redirectUrl,
      );
      store.updateWebViewParams(lastEncryptedParams, response.value.id);
      if (!isParent) {
        store.updateConsentHandle(
          response.value.data.consentHandle,
          response.value.id,
        );
      } else {
        // temp fix for backward compatibility
        const receivedConsentHandles = new Map(Object.entries(response.value.data.consentHandles));
        store.consentHandle = Object.values(response.value.data.consentHandles)[0] as string;
        store.consentHandleMap = receivedConsentHandles as Map<string, string>;
      }
    });

    if (failedResponses.length > 0) {
      log.error("Some requests failed while getting webview request details:");
      failedResponses.forEach((response: any) => {
        if (response.reason instanceof AxiosError) {
          apiErrorHandler(response.reason, events, store.requestId);
        }
      });
      return new Promise((resolve, reject) => {
        reject("Some error occurred in webview params");
      });
    }

    return lastEncryptedParams; // TODO: unused
  }

  function verifyAaOTPAndNext(otpFromUser: string) {
    flaggedEvents(ConductorEvent.PRE_AA_OTP_VERIFY, eventPayload(store.requestId, discriminator.value, {
      internal: true,
    }));
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.authVerify({
          otp: otpFromUser,
          otpReference: otpReference.value!,
        })
        .then(async () => {
          fireJourneyEvents(ConductorEvent.AA_OTP_VALID);
          aaAuth.value = true;
          // TODO: prepare for next screen to show
          lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
          if (
            layoutView !== JOURNEY_LAYOUTS.V6
            && layoutView !== JOURNEY_LAYOUTS.V7
            && layoutView !== JOURNEY_LAYOUTS.V8
          ) {
            prepareAccountsAndDiscoveryLinking();
            // transitionToView(views.discovery);
          }
          // viewHandler.value = components.discovery;

          log.silly("Verified AA OTP successfully");
          resolve(); // update the otp verification status
        })
        .catch((e: any) => {
          // TODO: handle AA interaction error here
          // maybe inform the caller that we could not talk to AA here?
          const handler = baseErrorHandlerFactory.createErrorHandler(AuthVerifyErrorHandler);
          handler.processError(e, reject);
        }).finally(() => {
          flaggedEvents(ConductorEvent.POST_AA_OTP_VERIFY, eventPayload(store.requestId, discriminator.value, {
            internal: true,
          }));
        });
    });
  }

  async function prepareAccountsAndDiscoveryLinking(config = { linkedAccountsSequence: 1 }) {
    isProcessing.value = true;

    try {
      // Initial setup remains the same
      await aaSdk
        .value!.consentRequestDetails(store.consentHandle!)
        .then((response: any) => {
          log.silly("Successfully resolved consent details with consent handle:", store.consentHandle);
          fireJourneyEvents(ConductorEvent.AA_CONSENT_DETAILS_RESOLVED);
          store.updateConsentRequestInfo(response.data);
        })
        .catch((e: any) => {
          const handler = baseErrorHandlerFactory.createErrorHandler(ConsentRequestErrorHandler);
          handler.processError(e);
        });

      await aaSdk
        .value!.getUserInfo()
        .then((response: any) => {
          log.silly("Got the user details from AA");
          store.updateUserInformation(response.data.UserInfo);
        })
        .catch((e: any) => {
          const handler = baseErrorHandlerFactory.createErrorHandler(UserInfoErrorHandler);
          handler.processError(e);
        });

      log.silly("Fetching available financial institutions for requestId:", store.requestId);

      const effectiveSequence = layoutView === JOURNEY_LAYOUTS.V10
        ? 2
        : config.linkedAccountsSequence;

      if (effectiveSequence === 2 && layoutView === JOURNEY_LAYOUTS.V10) {
        // V10 with discovery first
        lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_DISCOVERY;
        const discoveryQueue = await updateAndGetDiscoveryQueue();
        await handleDiscovery([], discoveryQueue);

        lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
        await handleLinkedAccounts();
      } else {
        // Original flow - linked accounts then discovery
        lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;
        const { linkedAccounts, discoveryQueue } = await handleLinkedAccounts();

        lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_DISCOVERY;
        await handleDiscovery(linkedAccounts, discoveryQueue);
      }
    } catch (error) {
      log.error("Error in account linking process:", error);
      handleFailures(
        ErrorCodes.UNKNOWN,
        ErrorTypes.FLOW_ERROR,
        "Error in account linking process",
        true,
        { error },
      );
    } finally {
      isProcessing.value = false;
    }
  }

  async function handleLinkedAccounts() {
    log.silly("Fetching already linked accounts from AA");
    lastKnownStatus.value = LastStatus.REQUIRES_LINKED_ACCOUNTS;

    try {
      const response = await aaSdk.value!.getLinkedAccounts();
      if (response.data && response.data.LinkedAccounts.length > 0) {
        const linkedAccounts = response.data.LinkedAccounts;
        let filteredLinkedAccounts = store.financialInstruments.length > 0
          ? getFilteredAccounts(linkedAccounts)
          : linkedAccounts;

        const filteredFips = utils.loadFipsBasedOnAA(
          store.consentDetail.value.fiTypes,
          Array.from(store.getInstitutions().value.values()),
          aaHandle.value,
        );

        filteredLinkedAccounts = filterAccountsByAvailableFips(
          filteredLinkedAccounts,
          filteredFips,
        );

        log.silly(
          "Fetched already linked accounts from AA, there are ",
          filteredLinkedAccounts?.length > 0 ? "a few" : "none",
          "linked",
        );
        store.updateLinkedAccounts(filteredLinkedAccounts);

        if (filteredLinkedAccounts.length > 0) {
          fireJourneyEvents(ConductorEvent.LINKED_ACCOUNTS_FETCHED);
          if (layoutView === JOURNEY_LAYOUTS.V2) {
            if (relevantLinkedAccounts(filteredLinkedAccounts)) {
              transitionToView(views.consentPage);
            } else {
              transitionToView(views.discovery);
            }
          } else {
            transitionToView(views.discovery);
          }
        } else {
          fireJourneyEvents(
            ConductorEvent.NO_LINKED_ACCOUNTS,
            {},
            {},
            { hasLinked: true },
          );
          transitionToView(views.discovery);
        }

        return {
          linkedAccounts,
          discoveryQueue: await updateAndGetDiscoveryQueue(linkedAccounts),
        };
      } else {
        log.debug("No linked accounts found");
        fireJourneyEvents(ConductorEvent.NO_LINKED_ACCOUNTS);
        transitionToView(views.discovery);
        return {
          linkedAccounts: [],
          discoveryQueue: await updateAndGetDiscoveryQueue(),
        };
      }
    } catch (e: any) {
      const handler = baseErrorHandlerFactory.createErrorHandler(
        FetchLinkedAccountsErrorHandler,
      );
      const discoveryQueue = await handler.processError(
        e,
        layoutView,
        views,
        transitionToView,
        updateAndGetDiscoveryQueue,
      );
      return { linkedAccounts: [], discoveryQueue };
    }
  }

  /*  async function setDiscoveryQueue() {
    // set discovery queue when priority of linked accounts is not 1st
    return await updateAndGetDiscoveryQueue();
  } */

  async function handleDiscovery(linkedAccounts: any[], discoveryQueue: Set<string>) {
    lastKnownStatus.value = LastStatus.REQUIRES_ACCOUNTS_DISCOVERY;
    const institutions = store.getInstitutions();
    const financialInstruments = store.financialInstruments;

    // Handle additional identifiers
    const extraIdentifiersNeeded: any = [];
    const identifierTypes: any = new Set();
    discoveryQueue.forEach((disc) => {
      const element = institutions.value?.get(disc) || "";
      if (element && layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(element)) {
        const identifiers = typeOfInfoRequired(element);
        identifiers.forEach((identifier) => {
          const type = identifier.type;
          if (type !== undefined && !identifierTypes.has(type)) {
            identifierTypes.add(type);
            extraIdentifiersNeeded.push(identifier);
          }
        });
      }
    });

    extraIdentifiersList.value = extraIdentifiersNeeded;
    askAdditionalInfo.value = true;

    if (discoveryQueue.size > 0) {
      await new Promise<any>((resolve, reject) => {
        isProcessing.value = true;
        let overallSuccess = false;
        const errors: any[] = [];
        const badRequests: any[] = [];

        const processQueue = async () => {
          for (const id of discoveryQueue) {
            console.log("Processing institution with id", id);
            const institution = institutions.value?.get(id);
            if (!institution) {
              log.error(
                            `Institution '${id}' in queue for discovery, but it's unavailable at the moment`,
              );
              errors.push({
                institution: id,
                error: "Institution unavailable",
              });
              continue;
            }

            let institutionProcessed = false;
            let retryCount = 0;
            const maxRetries = 3;

            while (!institutionProcessed || retryCount < maxRetries) {
              try {
                store.updateDiscoveryStatus(
                  { discovering: true, auto: false },
                  institution,
                );

                const result = (await doAccountDiscovery(
                  store.customer.mobile!,
                  institution,
                  financialInstruments,
                  0,
                  retryCount,
                  undefined,
                  false,
                )) as any;

                if (result.status === "ADDITIONAL_INFO_REQUIRED") {
                  console.log("Additional info required now:", id);
                  await new Promise<void>((resolveInfo) => {
                    setInterval(() => {
                      if (!askAdditionalInfo.value) {
                        resolveInfo();
                      }
                    }, 1000);
                  });
                  institutionProcessed = true;
                  retryCount++;
                  break;
                }

                if (result.sessionError) {
                  console.error(`Session error for institution: ${id}`);
                  errors.push({ institution: id, error: "Session error" });
                  break;
                }

                if (result.success) {
                  overallSuccess = true;
                  institutionProcessed = true;
                  break;
                } else if (result?.badRequest) {
                  badRequests.push(result.badRequest);
                  institutionProcessed = true;
                } else if (result.error) {
                  errors.push(result.error);
                  institutionProcessed = true;
                } else {
                  institutionProcessed = true;
                  errors.push(result);
                }
              } catch (error: any) {
                console.log("error Error processing institution", error);
                console.error(`Error processing institution ${id}:`, error);
                errors.push({
                  institution: id,
                  error: error.message || "Unknown error",
                });
                institutionProcessed = true;
              }
              retryCount++;
            }
            console.log("Completed processing institution", id);
          }
        };

        processQueue()
          .then(() => {
            resolve({ success: overallSuccess, errors, badRequests });
          })
          .catch((error) => {
            console.error(
              "An unexpected error occurred during queue processing",
              error,
            );
            handleFailures(
              ErrorCodes.UNKNOWN,
              ErrorTypes.FLOW_ERROR,
              "An unexpected error occurred during queue processing",
              true,
              { error },
            );
            reject({
              success: false,
              errors: [...errors, error],
              badRequests,
            });
          });
      });
    } else if ((discoveryQueue.size === 0 && layoutView !== JOURNEY_LAYOUTS.V2)
      || layoutView === JOURNEY_LAYOUTS.V5) {
      const isAutoDiscoveryEnabled = store.getFeature(
        ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY,
        true,
      );
      if (isAutoDiscoveryEnabled) {
        // Changed from result.then to direct await
        fireJourneyEvents(ConductorEvent.AUTO_DISCOVERY_MODE, {}, {}, { userInitiated: false });
        autoDiscoveryCount.value += 1;
        await autoDiscovery();
      }
    }
  }

  let doAccDiscoveryCallback: () => void; // temp fix: refactor later

  async function doAccountDiscovery(
    mobile: string,
    institution: Institution,
    instruments: string[],
    idSeq: number,
    retry = 0,
    limit = 3,
    auto = false,
  ) {
    log.silly(
      "Discovering accounts from AA for",
      institution.id,
      retry > 0 ? "retry (x" + retry + ")" : "",
    );

    if (retry >= limit) {
      store.updateDiscoveryStatus({ status: DiscoveryStatus.FAILED, auto, discovering: false }, institution);
      return { status: "MAX_RETRY_REACHED", institution };
    }
    if (layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(institution)) {
      // Set up the callback for when additional info is provided
      doAccDiscoveryCallback = async () => {
        return await doAccountDiscovery(mobile, institution, instruments, idSeq, retry, limit, auto);
      };
      extraIdentifiersList.value = typeOfInfoRequired(institution);
      askAdditionalInfo.value = true;
      // Return early to indicate additional info is needed
      return { status: "ADDITIONAL_INFO_REQUIRED", institution };
    }
    askAdditionalInfo.value = false;

    if (!askAdditionalInfo.value) {
      // Prepare the discovery request
      const request = {
        FIPDetails: {
          fipId: institution.id,
          fipName: institution.name,
        },
        FITypes: instruments,
        Customer: {
          Identifiers: [
            {
              category: "STRONG",
              type: "MOBILE",
              value: mobile,
            },
          ],
        },
      };

      const askDetails = store.getFeature(
        ConductorFeatures.ASK_USER_DETAILS,
        false,
      );

      if (askDetails || !isInfoRequired(institution)) {
        const extraUserInfo = getRequiredIdentifiers(institution);
        request.Customer.Identifiers.push(...extraUserInfo);
      }
      const sts = moment();
      try {
        const response = await aaSdk.value?.discoverAccounts(request);
        const result = { success: true, error: null, badRequest: null };

        const filteredDiscoveredAccounts = store.financialInstruments.length > 0
          ? getFilteredAccounts(response?.data?.DiscoveredAccounts)
          : response?.data?.DiscoveredAccounts;

        store.updateDiscoveredAccounts(filteredDiscoveredAccounts, institution, idSeq);
        store.updateDiscoveryStatus({ status: DiscoveryStatus.SUCCESS, auto, discovering: false }, institution);
        fireJourneyEvents(ConductorEvent.ACCOUNTS_DISCOVERED, request, {}, { auto });

        return result;
      } catch (e: any) {
        const handler = baseErrorHandlerFactory.createErrorHandler(AccountDiscoveryErrorHandler);
        return handler.processError(e, {
          auto,
          request,
          institution,
          start: sts,
        });
      }
    }
  }

  function updateAndGetDiscoveryQueue(linkedAccounts: LinkedAccount[] = []) {
    log.silly("Evaluating institutions for account discovery");
    // const toDiscover = new Set<string>(filterParser.value?.getInstitutions() as Set<string>);
    let toDiscover = filterKnownFips(
      Array.from(store.getInstitutions().value.values()),
      filterParser.value?.getInstitutions() as Set<string>,
    );
    log.silly(
      "Requested institutions for account discovery",
      Array.from(toDiscover),
    );
    // to support fip selction
    if (layoutView === JOURNEY_LAYOUTS.V2 || layoutView === JOURNEY_LAYOUTS.V5) {
      if (
        Array.from(toDiscover).length == 0
        && store.selectedFipList.length > 0
      ) {
        toDiscover = new Set(
          store.selectedFipList.map((fip: { id: any }) => {
            return fip.id ? fip.id : fip;
          }),
        );
      }
    }
    // to support fip selction
    if (
      layoutView === JOURNEY_LAYOUTS.V6
      || layoutView === JOURNEY_LAYOUTS.V8
    ) {
      if (
        Array.from(toDiscover).length == 0
        && store.selectedFipList.length > 0
      ) {
        /* toDiscover = new Set(store.selectedFipList.map((fip: { id: any; })=>{return fip.id})); */
        toDiscover = new Set(store.selectedFipList);
      }
    }

    const alreadyLinked = linkedAccounts.reduce(
      (result, current) => result.add(current.fipId),
      new Set<string>(),
    );
    log.silly("Already linked institutions", Array.from(alreadyLinked));
    // Remove already linked accounts from discovery queue
    // alreadyLinked.forEach((value) => toDiscover.delete(value));
    store.updateInstitutionDiscoveryQueue(Array.from(toDiscover));
    log.silly(
      "Institution(s) eligible for account discovery",
      Array.from(toDiscover),
    );
    return toDiscover;
  }

  function filterKnownFips(
    fipList: Institution[],
    accountFilters: Iterable<any> | ArrayLike<any>,
  ) {
    if (accountFilters) {
      const filteredFips = fipList.filter((instituition: Institution) => {
        return Array.from(accountFilters).some((account) => {
          return (
            account === instituition.id
            && instituition.aa.length > 0
            && instituition.aa.includes(aaHandle.value)
          );
        });
      });
      return filteredFips.length > 0
        ? new Set(filteredFips.map((data: { id: any }) => data.id))
        : new Set([]);
    } else {
      return new Set([]);
    }
  }

  async function handleConsentAction(
    action: ConsentAction,
    selectedAccounts: FinancialAccount[],
    exitWithConsentReject = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retryCount = 0,
    retryLimit = 3,
  ) {
    consentAction.value = action;
    const relevantAccountsMap
      = prepareAccountsForConsentAction(selectedAccounts);
    const isMultiConsent
      = extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT || JourneyType.AUTHORIZED_MULTI_CONSENT_IN_IFRAME;

    const relevantConsents = new Map(
      Array.from(relevantAccountsMap.entries()).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([consentHandleValue, accountslist]) =>
          (isMultiConsent && !exitWithConsentReject
            ? accountslist.length > 0
            : true) || exitWithConsentReject,
      ),
    );

    const results = await Promise.allSettled(
      Array.from(relevantConsents.entries()).map(
        async ([consentHandleValue, accountslist]) => {
          const consentReq = consentHandleValue; // actual value
          // [NEW] check if mapped request id is enabled for the journey
          const currRequestId = utils.getKeyFromValue(store.consentHandleMap, consentHandleValue) as string;
          let resolvedConsentAction = action;
          if (action === ConsentAction.ACCEPT && currRequestId && !store.isRequestEnabled(currRequestId)) {
            resolvedConsentAction = ConsentAction.DENY;
            const payload = eventPayload(store.requestId, discriminator.value, {
              consentAction: action,
              errorCode: "USER_DESELECTED",
              errorType: ErrorTypes.FLOW_ERROR,
              errorMessage: "Consent rejected due to user deselection of the consent request",
              exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
              childRequestId: extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT || JourneyType.AUTHORIZED_MULTI_CONSENT_IN_IFRAME ? currRequestId : undefined,
            });

            events.fire(ConductorEvent.ERROR, payload);
          }

          const request: any = {
            consentHandleId: consentReq,
            handleStatus: resolvedConsentAction,
            ver: store.consentRequestInfo?.ver,
            FIPDetails: _shapeFIPDetails(accountslist),
            FIU: {
              id: store.consentRequestInfo?.FIU.id,
            },
          };

          const retryLimitCount = retryLimit;
          let retryCount = 0;

          const increaseRetryCount = () => {
            return retryCount++;
          };

          while (retryCount <= retryLimitCount) {
            try {
              const response = await aaSdk.value?.consent(request);
              if (extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT || extraJourneyReq?.value.jtype === JourneyType.AUTHORIZED_MULTI_CONSENT_IN_IFRAME) {
                fireJourneyEvents(action === ConsentAction.ACCEPT
                  ? ConductorEvent.CONSENT_GRANTED
                  : ConductorEvent.CONSENT_DENIED, {}, {}, {
                  consentAction: resolvedConsentAction,
                  aaHandle: aaHandle.value,
                  childRequestId: utils.getKeyFromValue(store.consentHandleMap, consentHandleValue),
                }, { internal: true });
              }
              return response;
            } catch (error) {
              const handler = baseErrorHandlerFactory.createErrorHandler(ConsentActionErrorHandler);
              handler.processError(error, {
                action: resolvedConsentAction,
                consentHandleValue,
                retryCount,
                increaseRetryCount,
                retryLimitCount,
                utils,
              });
            } finally {
              events.clearTimedEvents();
            }
          }
        },
        /* } */
      ),
    );

    /* handling consent responses, if atleast one is successful , its a success.
      failure is only when all of them fail */
    const institutions = utils
      .getUniqueElementsByKeys(selectedAccounts, ["fipId", "fipName"])
      .map((i: any) => {
        return {
          id: i.fipId,
          name: i.fipName,
        };
      });
    const allFailed = results.filter(re => re.status === "rejected");
    const successFulConsent = results.filter(
      re => re.status === "fulfilled" && re.value !== undefined,
    );
    if (allFailed.length === relevantConsents.size && allFailed.length > 0) {
      if (allFailed[0].status === "rejected") {
        if (allFailed[0].reason.status === "FAILURE") {
          handleFailures(
            ErrorCodes.FAILURE,
            ErrorTypes.FLOW_ERROR,
            allFailed[0].reason.message,
            true,
            aaErrorContext(allFailed[0].reason),
          );
        } else if (allFailed[0].reason.status === "SESSION_ERROR") {
          handleSessionError();
        } else if (allFailed[0].reason.status === "BAD_REQUEST") {
          handleBadRequest(
            ErrorCodes.BAD_REQUEST,
            ErrorTypes.INPUT_ERROR,
            allFailed[0].reason.message,
            true,
            aaErrorContext(allFailed[0].reason),
          );
        }
      }
    } else {
      if (successFulConsent.length > 0) {
        if (action === ConsentAction.ACCEPT || action === ConsentAction.DENY) {
          fireJourneyEvents(
            action === ConsentAction.ACCEPT
              ? ConductorEvent.CONSENT_GRANTED
              : ConductorEvent.CONSENT_DENIED,
            {},
            {},
            eventPayload(store.requestId, discriminator.value, {
              institutions,
            }),
          );
        } else if (exitWithConsentReject) {
          fireJourneyEvents(ConductorEvent.CONSENT_DENIED, {}, {}, eventPayload(store.requestId, discriminator.value, {
            institutions,
          }));
        } else {
          // Fire an error instead
          const payload = eventPayload(store.requestId, discriminator.value, {
            consentAction: action,
            errorCode: "CONSENT_FAILED",
            errorType: "FLOW_ERROR",
            errorMessage: "Consent Failed due to error from AA",
            exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
            institutions: institutions,
          });
          events.fire(ConductorEvent.ERROR, payload);
        }
        return Promise.all(successFulConsent).finally(() => {
          removeEventListeners();
        });
      }
    }
  }

  function _shapeFIPDetails(selectedAccounts: FinancialAccount[]) {
    const mapped = selectedAccounts.reduce((result, current) => {
      let ob = result.get(current.fipId);
      if (!ob) {
        ob = {
          FIP: {
            id: current.fipId,
          },
          Accounts: [],
        };
        result.set(current.fipId, ob);
      }
      const acc = { ...current } as any;
      delete acc.identifierSeq;

      ob.Accounts.push(acc); // now push

      return result;
    }, new Map<string, any>());
    return Array.from(mapped.values());
  }

  async function ensureNext() {
    if (awaitNext.value) {
      await awaitNext.value;
      awaitNext.value = undefined;
    }
  }

  function _fireExit(
    errorCode = "UNKNOWN",
    errorType = "UNKNOWN",
    errorMessage = "Unhandled error encountered",
    extraPayload = {},
  ) {
    const payload = eventPayload(store.requestId, discriminator.value, {
      errorCode,
      errorType,
      errorMessage,
      exitStatus: lastKnownStatus.value || LastStatus.UNKNOWN, // share the last known status in the journey
      ...extraPayload,
    });
    events.fire(ConductorEvent.EXIT, payload);
    // emit an event for redirection if required
    emitter.emit(InternalConductorEvent.JOURNEY_EXIT);
    // Trigger app_failure as well
    events.fire("app_failure", payload);
    events.clearTimedEvents();
  }

  async function denyAndExit(
    exitErrorMessage: string = "",
    errorCode: string = "CANCELLED",
    exitWithConsentReject: boolean = true,
    errorType = "FLOW_ERROR",
    aaOperation?: string,
    aaOpAttempt?: number,
  ) {
    const canDeny = aaAuth.value;
    if (canDeny) {
      await handleConsentAction(ConsentAction.DENY, [], exitWithConsentReject);
      _fireExit(errorCode, errorType, "Unhandled error encountered", {
        aaOperation,
        aaOpAttempt,
      });
    } else if (
      !canDeny
      && lastKnownStatus.value === LastStatus.REQUIRES_AA_OTP
    ) {
      _fireExit(errorCode, errorType, ErrorMessages.JOURNEY_CANCELLED, {
        aaOperation,
        aaOpAttempt,
      });
    } else if (!exitWithConsentReject || !canDeny) { // If exit was not triggered by handleConsent, we need to do it now
      _fireExit(errorCode, errorType, exitErrorMessage || "", {
        aaOperation,
        aaOpAttempt,
      });
    }
  }

  async function _initForAlternateMobile(mobileNumber: string, resend = false) {
    if (mobileNumber === store.customer.mobile) {
      return Promise.reject(ErrorMessages.MOBILE_SAME_AS_USERID);
    }
    const req = { mobileNum: mobileNumber };
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.mobileAuthInit(req)
        .then((response: any) => {
          // checkForOTP('ALT_MOBILE');
          store.additionalMobiles.push(mobileNumber); // not for navi
          fireJourneyEvents(resend ? ConductorEvent.ALT_MOBILE_AA_OTP_RESENT : ConductorEvent.ALT_MOBILE_AA_OTP_SENT, {}, {}, aaErrorContext(response),
          );
          resolve();
        })
        .catch((error: any) => {
          if (error?.status === "ALREADY_REGISTERED") {
            store.additionalMobiles.push(mobileNumber); // not for navi
          }
          const handler = baseErrorHandlerFactory.createErrorHandler(MobileAuthInitErrorHandler);
          handler.processError(error, reject);
        });
    });
  }

  async function _confirmAlternateMobile(
    mobileNumber: string,
    inpOtpFromUser: string,
  ) {
    const request = {
      mobileNum: mobileNumber,
      otp: inpOtpFromUser,
    };
    return new Promise<void>((resolve, reject) => {
      aaSdk.value
        ?.mobileAuthVerify(request)
        .then(() => {
          // store.additionalMobiles.push(mobileNumber);
          fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VALID);
          resolve();
          // start discovery of the accounts using new mobile number
        })
        .catch((error: any) => {
          const handler = baseErrorHandlerFactory.createErrorHandler(MobileAuthVerifyErrorHandler);
          handler.processError(error, reject);
        });
    });
  }

  // Account linking related stuff
  async function initiateLinking(
    institution: Institution,
    selectedAccounts: FinancialAccount[],
    resend = false,
    retryCount = 0,
    retryLimit = 3,
  ) {
    const fip = { fipId: institution.id, fipName: institution.name };
    const accounts = selectedAccounts.map(a => a.shapedForLinking());
    const accountLinkingRequest = {
      FIPDetails: fip,
      Customer: {
        Accounts: accounts,
      },
    };
    const retryLimitCount = retryLimit;
    return await aaSdk.value
      ?.linkAccounts(accountLinkingRequest)
      .then((response: any) => {
        // OTP for linking sent successfully
        fireJourneyEvents(
          resend
            ? ConductorEvent.ACCOUNTS_LINKING_OTP_RESENT
            : ConductorEvent.ACCOUNTS_LINKING_OTP_SENT,
        );
        return {
          retryNow: false,
          response: response.data as AccountLinkingResponseProto,
        };
      })
      .catch(async (e: any) => {
        const badRequestCallBack = async () => {
          await initiateLinking(institution, selectedAccounts, true, ++retryCount, retryLimit);
        };
        const handler = baseErrorHandlerFactory.createErrorHandler(LinkAccountsErrorHandler);
        handler.processError(e, fip, retryCount < retryLimitCount, badRequestCallBack);
      });
  }

  async function confirmLinking(
    otp: number,
    refNum: string,
    meta: Institution,
  ) {
    const fipName = meta.name;
    const request = {
      AccountsLinkingRefNumber: refNum,
      token: otp,
    };
    // const request = new ConfirmLinkingRequest(refNum, otp);
    return await aaSdk.value
      ?.confirmLinking(request)
      .then((response: any) => {
        // update the store
        fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_VALID);
        store.linkDiscoveredAccounts(response.data as AccountLinkedResProto, meta);
        fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKED);
      })
      .catch((e: any) => {
        const handler = baseErrorHandlerFactory.createErrorHandler(ConfirmAccountLinkingErrorHandler);
        handler.processError(e, refNum, fipName);
      });
  }

  function exitTheWorld(
    quitely: boolean = false,
    errorMessage?: string,
    errorCode?: string,
  ) {
    if (quitely) {
      denyAndExit(
        errorMessage,
        errorCode,
        quitely, /** quitely = don't trigger exit */
      );
    } else {
      // show the modal
      exitWorld.value = true;
    }
  }

  function addMoreBankAccounts() {
    // viewHandler.value = components.selectBanks;
    transitionToView(views.selectBanks);
  }

  async function discoverAccountsFromInstitutions(
    institutions: Institution | Array<Institution>,
    retryLimit?: number,
    auto = false,
    mobile?: string,
  ) {
    const discoverList = Array.isArray(institutions) ? institutions : [institutions];
    const extraIdentifiersNeeded: any = [];
    const identifierTypes: any = new Set();
    discoverList.forEach((element) => {
      if (layoutView === JOURNEY_LAYOUTS.V2 && isInfoRequired(element)) {
        const identifiers = typeOfInfoRequired(element);

        identifiers.forEach((identifier) => {
          const type = identifier.type;
          if (type !== undefined && !identifierTypes.has(type)) {
            identifierTypes.add(type);
            extraIdentifiersNeeded.push(identifier);
          }
        });
      }
    });

    extraIdentifiersList.value = extraIdentifiersNeeded;
    askAdditionalInfo.value = true;

    isProcessing.value = true;

    let overallSuccess = false;
    const errors: any[] = [];
    const badRequests: any[] = [];

    for (const institution of discoverList) {
      console.log(`Starting discovery for: ${institution.id}`);

      let institutionProcessed = false;
      let retryCount = 0;
      while (!institutionProcessed || retryCount < (retryLimit || 3)) {
        try {
          store.updateDiscoveryStatus(
            {
              discovering: true,
              auto,
            },
            institution,
          );

          const result = await doAccountDiscovery(
            mobile || store.customer.mobile!,
            institution,
            store.financialInstruments,
            mobile ? 1 : 0,
            retryCount,
            retryLimit,
            auto,
          ) as any;

          if (result.status === "ADDITIONAL_INFO_REQUIRED") {
            console.log(`Additional info required for institution: ${institution.id}`);

            // Wait for additional info
            await new Promise<void>((resolveInfo) => {
              setInterval(() => {
                if (!askAdditionalInfo.value) {
                  resolveInfo();
                }
              }, 1000);
            });
            console.log(`Additional info received for institution: ${institution.id}`);
            // Continue to next iteration of while loop
            institutionProcessed = true;
            retryCount++;
            break;
          }

          // const result = await processDiscoveryResult(rawResult, auto, mobile ? 1 : 0) as any;
          if (result.sessionError) {
            console.error(`Session error for institution: ${institution.id}`);
            errors.push({ institution: institution.id, error: "Session error" });
            break; // Exit the while loop, move to next institution
          }

          if (result.success) {
            overallSuccess = true;
            institutionProcessed = true;
            break;
          } else if (result?.badRequest) {
            badRequests.push(result.badRequest);
            institutionProcessed = true;
          } else if (result.error) {
            errors.push(result.error);
            institutionProcessed = true;
          } else {
            institutionProcessed = true;
            errors.push(result);
          }
        } catch (error: any) {
          console.error(`Error processing institution ${institution.id}:`, error);
          errors.push({ institution: institution.id, error: error.message || "Unknown error" });
          institutionProcessed = true; // Consider it processed even if there's an error
        }

        retryCount++;
      }
      console.log(`Completed discovery for: ${institution.id}`);
    }
    if (!overallSuccess && !bankFound.value && !somethingLoading.value) {
      handleFailures(
        ErrorCodes.UNKNOWN,
        ErrorTypes.FLOW_ERROR,
        "Account discovery failed for all institutions",
        true,
        { errors, badRequests },
      );
    } else if (errors.length > 0 || badRequests.length > 0) {
      console.warn("Some institutions failed or had bad requests during account discovery", { errors, badRequests });
    }

    isProcessing.value = false;

    return { success: overallSuccess, errors, badRequests };
  }

  function navigateBack(from?: string) {
    if (from === "selectBanks") {
      // render the discovery view
      // viewHandler.value = components.discovery;
      transitionToView(views.discovery);
    }
  }

  async function retryLogin() {
    // reset complete store??
    // store.otpReference = undefined;
    // store.aaAuth = false;
    sessionError.value = false;
    isProcessing.value = true;
    eventEmitter.all.clear();
    store.$reset();
    await init(store.requestId);
    isProcessing.value = false;
  }

  async function resendMobileAuthOTP(mobileNum: string) {
    // reset store to get updated valuee
    await _initForAlternateMobile(mobileNum);
  }

  function handleAPIError(
    title: string,
    desc: string,
    errorMessage: string,
    errorCode = ErrorCodes.INVALID_CONFIG,
    errorType = ErrorTypes.API_ERROR,
  ) {
    if (
      store.viewsType?.error === COMPONENT_VIEW_TYPE.DEFAULT
      || layoutView === JOURNEY_LAYOUTS.V2
    ) {
      const error = {
        errorCode: ERROR_RESPONSE_CODES.DATA_NOT_FOUND,
        errorMessage: "Some error occurred! Please try again in sometime.",
      };
      xStore.errorXValue = error;
      transitionToView(v2Views.xErrorPage);
    } else {
      const ctx = {
        error: true,
        title: title,
        description: desc,
        useDefaultDesc: false,

        primaryText: "Got it, try other method",
        onPrimary: () => denyAndExit(errorMessage, errorCode, false, errorType),
      };
      // invoke troubleshoot helper
      invokeTroubleshoot(ctx);
    }
  }

  function fireJourneyEvents(
    eventType: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    request: any = {},
    errorObject = {} as any,
    extras = {},
    labels = {},
  ) {
    let error = {};
    // add specific request details in extraparams
    const extraParams = {
      aaHandle: aaHandle.value,
      requestId: store.requestId,
    };
    if (Object.entries(errorObject).length > 0) {
      error = {
        errorCode: errorObject.errorCode,
        errorType: errorObject.errorType,
        errorMessage: errorObject.errorMessage,
      };
    }
    const finalPayload = { ...extraParams, ...error, ...extras };
    events.fire(
      eventType,
      eventPayload(store.requestId, discriminator.value, finalPayload),
      labels,
    );
  }

  async function switchAA() {
    if (aaHandle.value === "nadl") {
      aaHandle.value = "finvu";
    }
    isProcessing.value = true;
    resetStore.store;
    await _initForAaLogin();
    isProcessing.value = false;
  }

  function collectTopPrefFips() {
    const countForAutoDisc = store.getFeature(
      ConductorFeatures.AUTO_DISCOVERY_FIP_COUNT,
    ) as number;
    const filteredFips = utils.loadFipsBasedOnAA(
      store.consentDetail.value.fiTypes,
      Array.from(store.getInstitutions().value.values()),
      aaHandle.value,
    );

    return filteredFips.slice(0, countForAutoDisc);
  }
  async function autoDiscovery(mobile?: string) {
    // use the collected top fips & trigger discovery
    const collectFips = collectTopPrefFips() as any;
    const autoRetryLimit = store.getFeature(
      ConductorFeatures.ACCOUNTS_AUTO_DISCOVERY_LIMIT,
    ) as number;
    const knownFilteredFips = filterKnownFips(
      Array.from(store.getInstitutions().value.values()),
      filterParser.value?.getInstitutions() as Set<string>,
    );
    autoRetryCount.value += 1;
    const accFilterList = Array.from(knownFilteredFips).map((data) => {
      return store.getInstitutions().value.get(data.toString());
    });
    let autoDiscList = [] as any;
    if (mobile) {
      // take the fip from account filter also to trigger alt mobile autodiscovery
      if (filterParser.value) {
        autoDiscList = Array.from(new Set(accFilterList.concat(collectFips)));
      } else {
        autoDiscList = collectFips;
      }
      await discoverAccountsFromInstitutions(
        autoDiscList,
        autoRetryLimit,
        true,
        mobile,
      );
      if (!bankFound.value) {
        emitter.emit(InternalConductorEvent.ALT_AUTO_DISCOVERY_FAILED);
        // in case of alternate mobile goes to missing account
        // transitionToView(views.missingAccount);
      }
    } else {
      // remove fip present in the account filter so that we dont trigger re-discovery

      autoDiscList = collectFips.filter(
        (item: any) => !Array.from(knownFilteredFips).includes(item.id),
      );
      await discoverAccountsFromInstitutions(
        autoDiscList,
        autoRetryLimit,
        true,
        store.customer.mobile!,
      );
      if (!bankFound.value) {
        emitter.emit(InternalConductorEvent.AUTO_DISCOVERY_FAILED);
      }
    }
  }

  function cleanUpFips(institution: any) {
    if (Array.isArray(institution)) {
      institution.forEach((element) => {
        const fa = institutionWiseAccounts.value.get(element.id);
        if (fa && fa.getDiscoveredAccStatus() !== DiscoveryStatus.SUCCESS) {
          institutionWiseAccounts.value.delete(element.id);
        }
      });
    } else {
      const fa = institutionWiseAccounts.value.get(institution.id);
      if (fa && fa.getDiscoveredAccStatus() !== DiscoveryStatus.SUCCESS) {
        institutionWiseAccounts.value.delete(institution.id);
      }
    }
  }

  // manipulate browser / mobile back buttons
  const onLoad = disableBackOnLoad.bind(null);
  const onPopState = disableOnPopState.bind(null);
  function disableBackOnLoad() {
    window.history.pushState({}, "");
  }
  function disableOnPopState() {
    window.history.pushState({}, "");
    exitWorld.value = true;
  }
  function removeEventListeners() {
    window.removeEventListener("load", onLoad);
    window.removeEventListener("popstate", onPopState);
  }

  watch(removeListener, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      removeEventListeners();
    }
  });

  // set journey features
  function setJourneyFeatures(data: any) {
    const defaultFeatures = DefaultFeatures();
    const listOfDefault = defaultFeatures.getListOfDefaults(layoutView);
    // Configure layout Features, set defaults if the values are missing from the feature list
    //  data?.features&& Object.keys(data.features).length > 0 --- add this when mrging in dev to the below condiction
    const resolvedFeatureList = data?.features ? data.features : {};
    for (const [key, value] of listOfDefault) {
      const featureValue
        = resolvedFeatureList[key] !== undefined
          ? resolvedFeatureList[key]
          : value;
      store.setFeature(key, featureValue);
    }

    // filtering out the design/theme features which affect the layout
    // const layoutThemes = filterTemplates('design', store.features);
    // store.themeTemplates = utils.splitTemplateString(layoutThemes, 3, undefined);
    store.themeTemplates = {
      colorFont: features.value.get(ConductorFeatures.COLOR_FONT),
      colorPrimary: features.value.get(ConductorFeatures.COLOR_PRIMARY),
      colorSecondary: features.value.get(ConductorFeatures.COLOR_SECONDARY),
      colorLoader: features.value.get(ConductorFeatures.COLOR_LOADER),
      colorSelection: features.value.get(ConductorFeatures.COLOR_SELECTION),
      colorInput: features.value.get(ConductorFeatures.COLOR_INPUT),
      colorInputActive: features.value.get(
        ConductorFeatures.COLOR_INPUT_ACTIVE,
      ),
      colorInputFocus: features.value.get(ConductorFeatures.COLOR_INPUT_FOCUS),
      colorInputDisabled: features.value.get(
        ConductorFeatures.COLOR_INPUT_DISABLED,
      ),
      fontFamily: features.value.get(ConductorFeatures.FONT_FAMILY),
      otpInputType: features.value.get(ConductorFeatures.OTP_INPUT_TYPE),
      otpInputSplitCount: features.value.get(
        ConductorFeatures.OTP_INPUT_SPLIT_COUNT,
      ),
      roundedInput: features.value.get(ConductorFeatures.ROUNDED_INPUT),
      roundedButton: features.value.get(ConductorFeatures.ROUNDED_BUTTON),
      backgroundBody: features.value.get(ConductorFeatures.BACKGROUND_BODY),
      backgroundPrimary: features.value.get(
        ConductorFeatures.BACKGROUND_PRIMARY,
      ),
      backgroundSecondary: features.value.get(
        ConductorFeatures.BACKGROUND_SECONDARY,
      ),
      customBorder: features.value.get(ConductorFeatures.CUSTOM_BORDER),
      strokeColor: features.value.get(ConductorFeatures.STROKE_COLOR),
    };
    initializeDebugEvents();
  }

  function setJourneyTemplates(data: any) {
    const defaultTempates = DefaultTemplates();
    // change here tenant value to get the tempaltes

    const listOfDefaultTemplates
      = defaultTempates.getListOfDefaultTemplates(layoutView);
    // Configure tempalte Features, set defaults if the values are missing from the template list
    const resolvedTemplateList = data?.templates ? data.templates : {};
    if (listOfDefaultTemplates) {
      for (const [key, value] of listOfDefaultTemplates) {
        const templateValue
          = resolvedTemplateList[key] !== undefined
            ? resolvedTemplateList[key]
            : value;
        store.setTemplate(key, templateValue);
      }
    }
    // separate the view related features heree
    const viewTemplates = filterTemplates("view", store.features);
    store.viewsType = utils.splitTemplateString(viewTemplates, 3, 4);
  }

  function triggerExitWorld(
    quite = false,
    errMsg?: string,
    errCode?: string,
    auto = false,
  ) {
    // conductor.exitTheWorld(quite, errMsg, errCode);
    // isLoading.value = true;

    if (auto) {
      // autoDiscoveryCount.value += 1;
      fireJourneyEvents(
        ConductorEvent.AUTO_DISCOVERY_MODE,
        {},
        {},
        { userInitiated: true },
      );
      autoDiscovery();
      store.autoDiscoveryTrigger = undefined;
    } else {
      exitTheWorld(quite, errMsg, errCode);
    }
    // isLoading.value = false;
    // console.log(autoDiscoveryCount.value)
  }

  function filterTemplates(type: string, listOfTemplates: any) {
    const templateMap = new Map<string, any>();
    for (const [key, value] of listOfTemplates) {
      // depends on type of view
      if (key.includes(type)) {
        templateMap.set(key, value);
      }
    }
    return templateMap;
  }

  function selectAAFromFips() {
    // try and use the filters and/or stored selected FIP(s)
    let selectedFips: string[] = [];
    if (filterParser.value) {
      selectedFips
        = filterParser.value?.getInstitutions().size > 0
          ? Array.from(filterParser.value?.getInstitutions())
          : [];
    } else {
      selectedFips = store.selectedFipList;
    }
    const availableFips = Array.from(store.getInstitutions().value.values());
    try {
      if (selectedFips.length !== 0) {
        aaHandle.value = utils.getAAFromFip(
          selectedFips,
          availableFips,
          store.availableAAs,
          store.tenantId,
        );
        // aaHandle.value = "nadl";
      } else {
        aaHandle.value
          = store.availableAAs.length > 0
            ? store.availableAAs[0].handle
            : "finvu";

        // aaHandle.value = "nadl";
      }
    } catch (error: any) {
      if (error?.message === ErrorMessages.UNSUPPORTED_FIP) {
        aaHandle.value = store.availableAAs[0].handle;
        // aaHandle.value = "finvu";
        fireJourneyEvents(
          ConductorEvent.UNAVAILABLE_FIP_SELECTED,
          JSON.stringify(selectedFips),
        );
      }
    }
    events.fire(
      ConductorEvent.AA_SELECTED,
      eventPayload(store.requestId, discriminator.value, {
        aaHandle: aaHandle.value,
        autoSelection: true,
      }),
    );
  }

  // for one & only V2X
  async function getXDetails() {
    if (
      extraJourneyReq.value !== undefined
      && extraJourneyReq.value.id
      && extraJourneyReq.value.ts
      && extraJourneyReq.value.iv
    ) {
      const id = extraJourneyReq.value.id;
      const data = extraJourneyReq.value.data;
      const ts = extraJourneyReq.value.ts;
      const iv = extraJourneyReq.value.iv;
      xStore.isV2X = true;

      // optional
      const request = {
        id: id,
        data: data,
        ts: ts,
        iv: iv,
      };

      await api
        .getConsentData(request)
        .then((response: any) => {
          if (response) {
            xStore.updateXDetails(response.data);
            transitionToView(views.selectBanks);
          } else {
            xStore.brandInfo.color = "#1C357E";
            xStore.brandInfo.logo = "https://finarkein.com/img/logo.93c8e6ce.svg";
            xStore.brandInfo.name = "Finarkein Analytics";
          }
        })
        .catch((e: any) => {
          console.log("Failed to process the request, error = " + e.message);
          console.log(e);
          brandInfo.value.color = "#1C357E";
          brandInfo.value.logo = "https://finarkein.com/img/logo.93c8e6ce.svg";
          brandInfo.value.name = "Finarkein Analytics";
          if (e?.response?.status === 400) {
            if (e.response.data?.orgInfo) {
              xStore.updateXDetails(e.response.data);
            }

            const error = {
              errorCode: e.response.data.errorCode,
              errorMessage: e.response.data.errorMessage,
            };
            xStore.errorXValue = error;
            transitionToView(views.xErrorPage);
          } else if (
            e.response.data.errorCode === ERROR_RESPONSE_CODES.DATA_NOT_FOUND
          ) {
            const error = {
              errorCode: ERROR_RESPONSE_CODES.DATA_NOT_FOUND,
              errorMessage:
                "Some error occurred! Please try again in sometime.",
            };
            xStore.errorXValue = error;
            transitionToView(views.xErrorPage);
          }
        });
    }
  }

  function createConsentRequest(request: any, type: string) {
    api
      .createConsentRequest(xid.value, request)
      .then(async (response: any) => {
        // we will get the request id from response , send to AA login

        if (response.status === 200) {
          reqId.value = response.data.requestId;
          if (reqId.value) {
            await updateAndGetWebviewParams(aaHandle.value);
            isProcessing.value = false;
            if (type === "proceed") {
              if (reqId.value) {
                router.push({
                  name: "v2u.landing",
                  params: { identifier: reqId.value },
                });
              }
              // selectAAFromFips();
              store.requestId = reqId.value;
              await getListOfFips(reqId.value);
              prepareAccountAggregator();
              console.log("hey", views);
              transitionToView(views.login);
            } else if (type === "sendLink") {
              const statusRequest = {
                requestId: reqId.value,
                status: journeyStatus.linkSent,
                message: "Link sent through SMS/Email",
                actionTimestamp: new Date().toISOString(),
              };
              await api.sendStatusUpdates(reqId.value, statusRequest);
              transitionToView(views.xProgress);
            }
          }
        }
      })
      .catch((e: any) => {
        console.log(e);

        if (e?.response?.status === 400) {
          const error = {
            errorCode: e.response.data.errorCode,
            errorMessage:
              "Some error occured. Please contact your administrator!!",
          };
          errorXValue.value = error;
          transitionToView(views.xErrorPage);
        }
        console.log("Failed to process the request, error" + e.message);
        // window.location.href = alternateRedirectUrl.value;
      });
  }

  async function resolveConfigCatFeatures() {
    const finalTenant = computed(() => {
      return tId.value ? tId.value : tenantId.value;
    });
    const configCat = useFeatures(finalTenant.value);
    const dummyEncrypt = configCat.getFeature(
      "cfmPortalRedirectResponseDummyEncrypt",
      false,
    );
    const redirectButton = configCat.getFeature(
      "cfmPortalRedirectOnButtonClick",
      false,
    );
    const modeOfRedirection = configCat.getFeature(
      "cfmPortalRedirectXMode",
      "both",
    );
    const tenantSpecificJourney = configCat.getFeature(
      "cfmPortalCustomAaUx",
      "finvu",
    );

    await Promise.all([
      dummyEncrypt,
      redirectButton,
      modeOfRedirection,
      tenantSpecificJourney,
    ]).then((flags: any[]) => {
      forTenantRedirectParams.value = flags[0];
      showRedirectButton.value = flags[1];
      redirectMode.value = flags[2];
      customAAJourneyList.value = store.getFeature(ConductorFeatures.CUSTOM_UI_SUPPORT) || flags[3];
      // customAAJourneyList.value = 'finvu';
    });
  }

  function unsupportedBank() {
    const statusRequest = {
      requestId: reqId.value ? reqId.value : xid.value,
      status: journeyStatus.cancelled,
      message: "Bank Unsupported",
      actionTimestamp: new Date().toISOString(),
    };
    // exit.value = true;
    api
      .sendStatusUpdates(reqId.value ? reqId.value : xid.value, statusRequest)
      .then((response: any) => {
        if (response) {
          redirectToTenant(xStore.alternateRedirectUrl);
        }
      });
    // console.log(redirectToTenant(alternateRedirectUrl.value));
  }

  function redirectToTenant(url: string) {
    const finalUrl = url;
    if (
      redirectMode.value === REDIRECT_MODE_ENUM.anubhavx
      || redirectMode.value === REDIRECT_MODE_ENUM.both
    ) {
      if (finalUrl && finalUrl.length > 0) {
        const redirectUrl = finalUrl;
        const url: URL = new URL(redirectUrl);
        const addedParams = url.searchParams;
        if (forTenantRedirectParams.value) {
          addedParams.set(
            "ecres",
            "9HjME5CiZOyg0BSdqKPWTXKLLFIoJX-k4Q8oBcO_Ci4nn5JTxQ2vG5RYOnGc5hiiosHXlIsMxfroFcTrcwjNP0JyiqqrI8wo88R70z_XDNd-xbsmsAYIeLi_AyBpgVKbwvX-pBemDhUzRtL6OA5HznMxWcDLqmjuXClZGxcxdZllLJPFbukeKCzvdlEMMNU1o7oqinAq41H25OaqgqFua_aO2tXh59desCcHgkFK8hhpQO2EYH9Qa2ULEHhfDI4L",
          );
          addedParams.set("resdate", "180120231959012");
          addedParams.set("fi", "BEhRWEFRH1VYTA==");
          window.location.href = url.toString();
        } else if (xid.value && !forTenantRedirectParams.value) {
          const id = xid.value;
          api.getResponseParams(id).then((response) => {
            if (response) {
              addedParams.set("ts", response.data.ts);
              addedParams.set("data", response.data.data);
              addedParams.set("iv", response.data.iv);
              window.location.href = url.toString();
            }
          });
        }
      }
    }
  }

  function handleAvailableAccountFilters(
    data: any,
    requestId: string,
    view?: any,
  ) {
    filterParser.value = new AccountFilterParser(data.accountFilters);
    console.log("filterParser.value", filterParser.value);
    console.log("webRedirectUrl.value", webRedirectUrl.value);
    if (filterParser.value.getInstitutions().size > 0) {
      // EVENT: FIPs are selected
      // TODO: Change to INSTITUTION_SELECTED ?
      events.fire(
        ConductorEvent.FIP_SELECTED,
        eventPayload(requestId, discriminator.value, {
          institutions: Array.from(filterParser.value.getInstitutions()).map(
            (v) => {
              return {
                id: v, // @DEPRECATED
                fipId: v,
              };
            },
          ),
        }),
      );

      // We need to move to aa-login view
      lastKnownStatus.value = LastStatus.REQUIRES_AA_SELECTION;

      // get bank list before we initialize with AA
      selectAAFromFips();
      if (layoutView == JOURNEY_LAYOUTS.V7) {
        transitionToView(view);
      } else {
        prepareAccountAggregator();

        // feature tage for landing page preview
        if (showlanding.value) {
          transitionToView(views.landingPage);
        } else {
          if (customAAJourneyList.value
            && customAAJourneyList.value.includes(aaHandle.value)) {
            transitionToView(views.login);
          }
        }
      }
      return; // go back from here!
    }
  }

  function handleUnavailableAccountFilters(
    data: any,
    requestId: string,
    view?: any,
  ) {
    lastKnownStatus.value = LastStatus.REQUIRES_AA_SELECTION;

    switch (layoutView) {
      case JOURNEY_LAYOUTS.V5:
        transitionToView(views.selectBanks);
        break;
      case JOURNEY_LAYOUTS.V2:
        eventEmitter.emit(InternalConductorEvent.NO_ACCOUNTS_FOUND);
        if (showlanding.value) {
          transitionToView(views.landingPage);
        } else {
          transitionToView(views.selectBanks);
        }
        break;
      case JOURNEY_LAYOUTS.V6:
      case JOURNEY_LAYOUTS.V8:
        transitionToView(views.selectBanks);
        break;
      case JOURNEY_LAYOUTS.V7:
        selectAAFromFips();
        transitionToView(view);
        break;
      case JOURNEY_LAYOUTS.V10:
        console.log("moving to login view");
        selectAAFromFips();
        prepareAccountAggregator();
        transitionToView(views.login);
        break;
      default:
        log.fatal(
          "Unable to complete the journey at the moment, no institution provided",
        );
        handleBadRequest(
          ErrorCodes.INVALID_INSTITUTION,
          ErrorTypes.INPUT_ERROR,
          ErrorMessages.NO_INSTITUTION,
          false,
        );
    }

    return; // go back from here
  }

  function getRequiredIdentifiers(toBeDiscoveredFip: Institution) {
    // check here if required details are present or not
    let filteredUserDetails = [];
    if (store.extraUserDetails && store.extraUserDetails.length > 0) {
      filteredUserDetails = store.extraUserDetails?.filter(
        (eud: { type: string; value: string | undefined }) =>
          toBeDiscoveredFip.identifiers!.some(
            df =>
              df.type === eud.type
              && df.type !== "MOBILE"
              && (eud.value !== undefined || eud.value !== ""),
          ),
      );

      filteredUserDetails = filteredUserDetails.map(
        (eud: { type: any; value: any }) => {
          const matchingIdentifier = toBeDiscoveredFip.identifiers?.find(
            df => df.type === eud.type,
          );
          return {
            type: eud.type,
            category: matchingIdentifier?.category,
            value: eud.value,
          };
        },
      );
    }
    return filteredUserDetails?.length ? filteredUserDetails : [];
  }

  function isInfoRequired(providedInst: Institution) {
    // check here if extra info is required or not
    const availableData = [];
    const nonAvailableData = [];

    for (const inst of (providedInst?.identifiers || [])) {
      const matchingExtra = extraUserDetails.value.find(
        (data: { type: any; value: any }) =>
          data.type === inst.type
          && (data.value !== undefined || data.value !== ""),
      );
      if (inst.type !== "MOBILE") {
        if (matchingExtra) {
          availableData.push(matchingExtra);
        } else {
          nonAvailableData.push(inst);
        }
      }
    }

    return nonAvailableData.length > 0 ? true : false;
  }

  function typeOfInfoRequired(providedInst: Institution) {
    const availableData = [];
    const nonAvailableData = [];
    for (const inst of (providedInst?.identifiers || [])) {
      const matchingExtra = extraUserDetails.value.find(
        (data: { type: any; value: any }) =>
          data.type === inst.type
          && (data.value !== undefined || data.value !== ""),
      );
      if (inst.type !== "MOBILE") {
        if (matchingExtra) {
          availableData.push(matchingExtra);
        } else {
          nonAvailableData.push(inst);
        }
      }
    }
    return nonAvailableData;
  }

  const isExtraIdentifierProcessing = ref(false);

  async function updateExtraUserDetails($event: any) {
    isExtraIdentifierProcessing.value = true;
    setTimeout(async () => {
      askAdditionalInfo.value = false;
      $event.forEach((ele: { type: any; category: any; value: any }) => {
        const element = extraUserDetails.value.filter(
          (ei: { type: any }) => ei.type === ele.type,
        );
        if (element.length === 0) {
          extraUserDetails.value.push({
            type: ele.type,
            category: ele.category,
            value: ele.value,
          });
        } else {
          element[0].type = ele.type;
          element[0].category = ele.category;
          element[0].value = ele.value;
          const somethingNew = extraUserDetails.value.map(
            (obj: { type: any }) => (obj.type === ele.type ? element[0] : obj),
          );
          extraUserDetails.value = somethingNew;
        }
      });
      if (doAccDiscoveryCallback) {
        await doAccDiscoveryCallback();
      }
      isExtraIdentifierProcessing.value = false;
    }, 500);
  }

  async function getListOfFips(id?: string) {
    await api
      .getBankList(
        id ? id : store.requestId,
        0,
        1000,
        store.extraJourneyReq?.receivedAuth,
      )
      .then((response) => {
        const data = response.data;
        store.updateInstitutions(data.content); // paginated response this is!
        log.silly(
          "Fetched all financial institutions for requestId:",
          store.requestId,
        );
      })
      .catch((e: any) => {
        log.fatal(
          "Fetched all financial institutions for requestId:",
          store.requestId,
          e,
        );
      });
  }

  // mode and grouping as per features

  function transformText(text: string) {
    if (text === "DEPOSIT") {
      return "Bank Account(s)";
    }
    const words = text.split("_");
    const transformedText = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return transformedText;
  }

  const isSelected = (id: string, selectedBank: any) => {
    return selectedBank.value.includes(id);
  };

  function toggleSelectFip(id: string, selectedBank: any, mode: any) {
    if (mode == SELECTION_MODE.SINGLE) {
      if (!isSelected(id, selectedBank)) {
        selectedBank.value = [];
        selectedBank.value.push(id);
      } else {
        selectedBank.value.splice(selectedBank.value.indexOf(id), 1);
      }
    } else {
      if (isSelected(id, selectedBank)) {
        selectedBank.value.splice(selectedBank.value.indexOf(id), 1);
      } else {
        selectedBank.value.push(id);
      }
    }
  }

  function isBankSelected(groupId: any, bankId: any, selectedBanks: any) {
    return selectedBanks.value.some(
      (group: { groupId: any; bankIds: string | any[] }) => {
        if (group.groupId === groupId) {
          return group.bankIds.includes(bankId);
        }
        return false;
      },
    );
  }

  function toggleSelectAllFip(
    groupId: string,
    bankId: string,
    selectedBanks: any,
    mode: any,
  ) {
    const groupIndex = selectedBanks.value.findIndex(
      (group: any) => group.groupId === groupId,
    );

    if (mode == SELECTION_MODE.SINGLE) {
      if (
        groupIndex !== -1
        && selectedBanks.value[groupIndex].bankIds.includes(bankId)
      ) {
        // If the bank is already selected, deselect it
        selectedBanks.value = [];
      } else {
        // If mode is single, clear the existing selections and add the new one
        selectedBanks.value = [
          {
            groupId,
            bankIds: [bankId],
          },
        ];
      }
    } else {
      if (groupIndex === -1 && bankId) {
        // If the group doesn't exist and bankId is provided, create it and add the bankId
        selectedBanks.value.push({
          groupId,
          bankIds: [bankId],
        });
      } else if (groupIndex !== -1) {
        // If the group exists, update the selected bank IDs
        const group = selectedBanks.value[groupIndex];
        const bankIndex = group.bankIds.indexOf(bankId);

        if (bankIndex === -1 && bankId) {
          // Add the bank to the group if bankId is provided
          group.bankIds.push(bankId);
        } else if (bankIndex !== -1) {
          // Remove the bank from the group
          group.bankIds.splice(bankIndex, 1);
        }

        // If the group becomes empty, remove the group
        if (group.bankIds.length === 0) {
          selectedBanks.value.splice(groupIndex, 1);
        }
      }
    }
  }
  // rn the request key is static
  async function getBulkFileuPload(input: []) {
    try {
      const response = await api.getBulkFileuPload(
        store.requestId,
        input,
        store.extraJourneyReq?.receivedAuth,
      );
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  function getFilteredAccounts(availableAccounts: any) {
    return availableAccounts.filter((acc: { FIType: string }) => {
      return store.listOfFinancialInstruments.includes(acc.FIType);
    });
  }

  function filterAccountsByAvailableFips(accounts: any, fips: any[]) {
    return accounts.filter((acc: { fipId: string }) => {
      return fips.some(fip => fip.id === acc.fipId);
    });
  }

  function prepareAccountsForConsentAction(listOfAccounts: FinancialAccount[]) {
    const consentAccountsMap = new Map<string, FinancialAccount[]>();
    for (const [key, value] of store.consentMappedWithHandle) {
      const totalAccounts = listOfAccounts.filter(loa =>
        value.def.fiTypes.includes(loa.FIType),
      );
      // consent template id & list of relevant accounts
      consentAccountsMap.set(key, totalAccounts);
    }
    return consentAccountsMap;
  }

  function flaggedEvents(eventName: string, payload: Record<string, any>, labels?: Record<string, any>) {
    if (store.getFeature(ConductorFeatures.DEBUG_EVENTS, false)) {
      events.fire(eventName, payload, labels);
    }
  }

  function initializeDebugEvents() {
    // initialize debug events
    const debugEvents = store.getFeature(ConductorFeatures.DEBUG_EVENTS, false);
    const debugHeartBeat = store.getFeature(ConductorFeatures.DEBUG_EVENTS_FIRE_HEARTBEAT, debugEvents);
    const canFireHeartbeatEvents = debugHeartBeat !== undefined ? debugHeartBeat : debugEvents;
    canFireHeartbeatEvents ? events.canFireHeartbeatEvents() : "";
  }

  function fetchUserConsentedAccounts() {
    api.getUserConsentedAccounts(store.requestId, store.extraJourneyReq?.receivedAuth).then((response) => {
      if (response) {
        userConsentedAccounts.value = response.data.content;
      }
    });
  }

  function relevantLinkedAccounts(availableLinkedAccounts: FinancialAccount[]) {
    const filteredFips = filterParser.value?.getInstitutions() ? Array.from(filterParser.value?.getInstitutions()) || [] : [];

    // consider fips in account filters or selected fips are user selection
    const fipSelectedByUser = Array.from(new Set(store.selectedFipList.concat(filteredFips))) as string[];
    // prepare availableFips Set from the given relevant linked accounts;
    const availableFips = new Set(availableLinkedAccounts.map(account => account.fipId));

    // Check if all selectedFips are in the availableFips Set
    const allFipsPresent = fipSelectedByUser.every((fip: string) => availableFips.has(fip));
    return allFipsPresent;
  }

  return {
    missingAcc,
    totalAccountsCount,
    bankFound,
    anythingLoading,
    viewHandler,
    currentView,
    previousHandler,
    init,
    ensureNext,
    cancelAndExit: denyAndExit,
    exitTheWorld,
    handleSessionError,
    handleBadRequest,
    handleAPIError,

    // AA specific stuff
    resendAaOTP,
    verifyAaOTPAndNext,
    handleConsentAction,
    doAccountDiscovery,
    _initForAlternateMobile,
    _confirmAlternateMobile,
    initiateLinking,
    confirmLinking,
    addMoreBankAccounts,

    // exporting the components
    // components: views,
    // getInstitutionListForDisplay
    discoverAccountsFromInstitutions,
    navigateBack,
    resendMobileAuthOTP,

    // events
    fireJourneyEvents,
    // getAABasedOnFip,
    handleFailures,
    switchAA,
    autoDiscovery,
    collectTopPrefFips,
    transitionToView,
    views,

    // for disabling back functionality
    disableBackOnLoad,
    disableOnPopState,
    onLoad,
    onPopState,
    removeEventListeners,

    // feature resolvers
    setJourneyFeatures,
    triggerExitWorld,
    filterTemplates,
    fipFilters,
    filterKnownFips,
    prepareAccountAggregator,
    prepareAccountsAndDiscoveryLinking,
    selectAAFromFips,
    // v2X variables
    getXDetails,
    createConsentRequest,
    resolveConfigCatFeatures,
    unsupportedBank,
    redirectToTenant,

    retryLogin,
    getRequiredIdentifiers,
    isInfoRequired,
    updateExtraUserDetails,

    toggleSelectFip,
    isSelected,
    transformText,
    isBankSelected,
    toggleSelectAllFip,
    getBulkFileuPload,
    eventPayload,
    isExtraIdentifierProcessing,
    flaggedEvents,
    cleanUpFips,
  };
}