<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      width="40"
      height="40"
      rx="20"
      fill="#FAFAFA" />
    <path
      d="M23.3829 26.2587L26.2535 23.3881L32.0037 29.1382C32.7947 29.9292 32.8035 31.2179 32.0037 32.0177C31.2127 32.8087 29.924 32.8176 29.1242 32.0177L23.374 26.2676L23.3829 26.2587Z"
      fill="#1F002A" />
    <path
      d="M18.5175 29.5532C24.6384 29.5532 29.5779 24.6012 29.5779 18.5054C29.5779 12.4096 24.6258 7.45752 18.5175 7.45752C12.4091 7.45752 7.45703 12.4096 7.45703 18.5054C7.45703 24.6012 12.4091 29.5532 18.5175 29.5532Z"
      fill="white"
      stroke="#1F002A"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M23.8321 13.2047C22.5734 11.6482 20.6518 10.6489 18.4899 10.6489C15.444 10.6489 12.8594 12.6378 11.9658 15.3762"
      fill="white" />
    <path
      d="M23.8321 13.2047C22.5734 11.6482 20.6518 10.6489 18.4899 10.6489C15.444 10.6489 12.8594 12.6378 11.9658 15.3762"
      stroke="#1F002A"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>